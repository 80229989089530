import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/campaign/",
    children: [
      {
        path: ":id(\\d+)",
        name: "CampaignSummary",
        component: () => import("@/modules/campaign/views/CampaignSummary.vue"),
        meta: {
          title: "modules.campaign.summary.meta.title",
        },
      },
      {
        path: ":id/approval",
        name: "CampaignApproval",
        component: () =>
          import("@/modules/campaign/views/CampaignApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.campaign.approval.meta",
          permission: Permission.CampaignApproval,
        },
      },
      {
        path: "edition/:id/approval",
        name: "CampaignEditionApproval",
        component: () =>
          import("@/modules/campaign/views/CampaignEditionApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.campaign.approval.meta",
          permission: Permission.CampaignApproval,
        },
      },
      {
        path: "/campaigns/pending",
        name: "PendingCampaigns",
        component: () =>
          import("@/modules/campaign/views/PendingCampaigns.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.campaign.pendingCampaigns.meta.title",
          permission: Permission.CampaignApproval,
        },
      },
      {
        path: "/campaigns/drafts",
        name: "CampaignDrafts",
        component: () => import("@/modules/campaign/views/CampaignDrafts.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.campaign.campaigndrafts.meta.title",
          permission: Permission.CampaignApproval,
        },
      },
      {
        path: "/campaigns",
        name: "Campaigns",
        component: () => import("@/modules/campaign/views/TheCampaigns.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.campaign.campaigns.meta.title",
          permission: Permission.CampaignApproval,
        },
      },
    ],
  },
];
