import {
  DsNotifier,
  useDsSystemNotifierStore,
  useNetworkStore,
} from "@devsalsa/vue-core";

import i18n from "@/i18n";

export default {
  install() {
    // configure the app
    window.addEventListener("offline", () => {
      useNetworkStore().setOffline();
      if (useDsSystemNotifierStore().networkNotificationId === 0) {
        const id = DsNotifier.sendInfo({
          message: i18n.global.t("common.error.global.offline"),
          duration: 0,
          enableClose: false,
          position: "top-0 right-0",
          overlay: true,
        });
        useDsSystemNotifierStore().setNetworkId(id);
      }
    });

    window.addEventListener("online", () => {
      useNetworkStore().setOnline();
      DsNotifier.delete(useDsSystemNotifierStore().networkNotificationId);
      useDsSystemNotifierStore().setNetworkId(0);
    });
  },
};
