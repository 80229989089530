import { DsFlashNotifier } from "@devsalsa/vue-core";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import CreatorService from "@/modules/creator/services/CreatorService";

import i18n from "@/i18n";

export default class CreatorGuard {
  static async findLatestCreator(
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const latestCreator = await CreatorService.getLatest();

    if (latestCreator.id) {
      return next({
        name: "CreatorApproval",
        params: {
          id: latestCreator.id,
        },
      });
    }

    DsFlashNotifier.info(i18n.global.t("modules.creator.approvalBusy.message"));

    return next({
      name: "PendingCreators",
    });
  }
}
